import highlightActiveRoute from './highlightActiveRoute'
import initCalculator from './dpicalc'
import initSwiper from './initSwiper'
import { NAVLINK_CLASSNAME } from '../constants'

function loadPage(route) {
    fetch(route)
        .then(function (response) {
            return response.text()
        })
        .then(function (html) {
            var parser = new DOMParser()
            var doc = parser.parseFromString(html, 'text/html')
            const newTitle = doc.querySelector('head title')
            const newContent = doc.querySelector('.content')
            const oldContent = document.querySelector('.content')
            const oldTitle = document.querySelector('head title')
            oldContent.innerHTML = newContent.innerHTML
            oldTitle.innerHTML = newTitle.innerHTML
            // reinit when HTML changes
            highlightActiveRoute()
            initCalculator()
            initSwiper()
        })
        .catch(function (err) {
            console.warn('Something went wrong.', err)
        })
}

function initRouting() {
    const links = document.querySelectorAll(NAVLINK_CLASSNAME)

    links.forEach((link) => {
        link.addEventListener('click', function (e) {
            e.preventDefault()
            loadPage(e.target.href)
            history.pushState(null, '', e.target.href)
            window.scrollTo(0, 0)
        })
    })

    window.addEventListener('popstate', (e) => {
        e.preventDefault()
        loadPage(window.location.href)
    })
}

export default initRouting
